/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import React from 'react';
import Button from '@common_button';
import Dialog from '@common_dialog';
import Typography from '@common_typography';
import Divider from '@common_divider';
import SearchIcon from '@root/public/assets/img/icon/search-normal.svg';
import XMarkIcon from '@heroicons/react/24/solid/XMarkIcon';
import Show from '@common_show';
import Router from 'next/router';
import useUserAgent from '@hook/useUserAgent';
import cx from 'classnames';
import { getProductByKey, getProductKeyword } from '@core_modules/theme/services/graphql';
import { useTranslation } from 'next-i18next';
import useMediaQuery from '@hook/useMediaQuery';

let delayTimer;

const DialogSearch = (props) => {
    const {
        open,
        setOpen,
        storeConfig,
    } = props;

    const { isIOS } = useUserAgent();
    const { isTablet, isMobile } = useMediaQuery();

    const { t } = useTranslation(['common']);
    const [searchText, setSearchText] = React.useState(null);
    const [actGetProduct, { data, loading }] = getProductByKey();
    const { data: dataKeywords } = getProductKeyword();
    const [keyboardOpen, setKeyboardOpen] = React.useState(false);
    const mount = React.useRef(null);
    const timeoutOpenRef = React.useRef(null);
    const inputSearchRef = React.useRef(null);
    const keywords = dataKeywords?.topSearchQuery || [];

    const handleFocus = () => {
        setKeyboardOpen(true);
    };

    const handleBlur = () => {
        setKeyboardOpen(false);
    };

    React.useEffect(() => {
        if (open && isIOS) {
            inputSearchRef?.current?.focus();
        }
    }, [open]);

    React.useEffect(() => () => {
        mount.current = true;
        clearTimeout(timeoutOpenRef.current);
        if (mount.current && inputSearchRef?.current) {
            inputSearchRef?.current?.addEventListener('focus', handleFocus);
            inputSearchRef?.current?.addEventListener('blur', handleBlur);
        }

        return () => {
            mount.current = false;
            inputSearchRef?.current?.removeEventListener('focus', handleFocus);
            inputSearchRef?.current?.removeEventListener('blur', handleBlur);
        };
    }, []);

    const dataProduct = React.useMemo(() => {
        const result = [];
        if (data) {
            const dataProducts = data?.products?.items;
            for (let index = 0; index < dataProducts.length; index++) {
                const element = dataProducts[index];
                if (!element?.is_oppo_care) {
                    const prod = {
                        id: element.id,
                        name: element.name,
                        seller_name: '',
                        url_key: element.url_key,
                        position: index,
                        small_image: element.small_image,
                        price_tiers: element.price_tiers,
                        price_range: element.price_range,
                        type: 'product',
                    };
                    result.push(prod);
                }
            }
        }
        return result;
    }, [data]);

    /**
     * ---------------------------------------------------- *
     * @function postSearch
     * @summary post search
     * ---------------------------------------------------- *
     */
    const postSearch = async (text) => {
        actGetProduct({
            variables: {
                search: text,
            },
        });
    };

    const onChangeSearch = (e) => {
        const text = e.target.value;
        // setData([]);
        setSearchText(text === '' ? null : text);
        if (text === '') {
            clearTimeout(delayTimer);
        } else {
            clearTimeout(delayTimer);
            delayTimer = setTimeout(() => postSearch(text), 1000);
        }
    };

    const handleOnClickItem = (item) => {
        const { result: resultType, id: seller_id, url_key } = item;
        if (resultType === 'seller') {
            Router.push(
                {
                    pathname: '/[...slug]',
                    query: {},
                },
                `/seller/${seller_id}`,
            );
        } else {
            Router.push(
                {
                    pathname: '/[...slug]',
                    query: {},
                },
                `/${url_key}`,
            );
        }
        setOpen(false);
    };

    const handleOnClickKeyword = (keyword) => {
        setOpen(false);
        Router.push(`/catalogsearch/result?q=${keyword}`);
    };

    const onHandleKeyPress = (e) => {
        if (e.key === 'Enter') {
            Router.push(`/catalogsearch/result?q=${e.target.value}`);
            timeoutOpenRef.current = setTimeout(() => {
                setOpen(false);
            }, [1000]);
        }
    };

    return (
        <div className="dialog-search">
            <Button
                variant="plain"
                className="!p-0"
                onClick={() => setOpen(true)}
                ariaLabel="button-search-icon"
            >
                <SearchIcon
                    className={cx(
                        'w-[24px]',
                        'ml-1',
                    )}
                />
            </Button>
            <Dialog
                {...props}
                classBackdrop="!bg-opacity-90"
            >
                <Button
                    variant="plain"
                    className="btn-search-close cursor-pointer bg-neutral-white !rounded-full absolute z-[9999] top-3 right-5 !px-[12px] py-[12px]"
                    onClick={() => setOpen(false)}
                >
                    <XMarkIcon className="text-neutral-black w-5 h-5" />
                </Button>
                <div
                    className={
                        cx(
                            'search-container',
                            open && 'absolute',
                            open && isIOS && isMobile && keyboardOpen && 'top-[20%]',
                            open && isIOS && isMobile && !keyboardOpen && 'top-[20%]',
                            open && isIOS && isTablet && keyboardOpen && '-top-[7%]',
                            open && isIOS && isTablet && !keyboardOpen && 'top-[2px]',
                            'max-w-[380px] w-[100%]',
                        )
                    }
                >
                    <div
                        className={cx(
                            'section-dialog-search-title text-center',
                            !isIOS && 'mb-space-1000',
                            isIOS && open && 'mb-space-100',
                        )}
                    >
                        <Typography
                            className={cx('!text-5xl mobile:!text-2xl !text-neutral-white font-normal')}
                        >
                            {t('common:label:hiCanWeHelp')}
                        </Typography>
                    </div>
                    <div
                        className={
                            cx(
                                'section-dialog-search-content bg-neutral-white mobile:p-space-500 rounded-[20px]',
                                !isIOS && 'p-space-1000',
                                isIOS && 'p-space-500',
                            )
                        }
                    >
                        <div className="flex rounded-full border-[1px] border-neutral-black px-space-500 py-space-400 gap-[10px]">
                            {
                                React.cloneElement((
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z" stroke="#3F3F46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M22 22L20 20" stroke="#3F3F46" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                ), {
                                    className: 'w-[20px] h-[20px]',
                                })
                            }
                            <input
                                ref={inputSearchRef}
                                placeholder={dataProduct?.length > 0 ? dataProduct[0]?.name : (storeConfig?.pwa?.placeholder_search || '')}
                                className="w-full outline-0"
                                onChange={onChangeSearch}
                                onKeyDown={onHandleKeyPress}
                            />
                        </div>
                        <Show when={searchText !== null}>
                            <div className="section-quick-link my-5">
                                <div className="section-quick-link-title">
                                    <Typography className="!text-2xl font-medium">
                                        {t('common:label:quickLink')}
                                    </Typography>
                                </div>
                                <div className="section-quick-link-content mt-4 flex flex-col gap-2">
                                    <Show when={!loading && dataProduct?.length < 1}>
                                        <div className="section-quick-link-content-empty">
                                            {t('common:label:productSearchNotFound')}
                                        </div>
                                    </Show>
                                    <Show when={loading && dataProduct?.length < 1}>
                                        <div className="section-quick-link-content-empty">
                                            {`${t('common:label:loading')}...`}
                                        </div>
                                    </Show>
                                    <Show when={!loading && dataProduct?.length > 0}>
                                        {
                                            dataProduct?.map((item, index) => (
                                                <Button
                                                    key={`button-search-${index}`}
                                                    variant="plain"
                                                    onClick={() => handleOnClickItem(item)}
                                                    className="!p-0"
                                                >
                                                    <Typography className="!text-lg font-normal first-letter:uppercase">
                                                        {item?.name || '-'}
                                                    </Typography>
                                                </Button>
                                            ))
                                        }
                                    </Show>
                                </div>
                            </div>
                            <Divider />
                        </Show>
                        <Show when={keywords?.length > 0}>
                            <div className="sectino-popular-link mt-space-500">
                                <div className="section-popular-link-title">
                                    <Typography className="!text-2xl font-medium">
                                        {t('common:label:popularLink')}
                                    </Typography>
                                </div>
                                <div className="section-popular-link-content mt-4 flex flex-col gap-2">
                                    {
                                        keywords?.map((item, index) => (
                                            <Button
                                                key={`button-search-${index}`}
                                                variant="plain"
                                                onClick={() => handleOnClickKeyword(item?.keywords || '')}
                                                className="!p-0"
                                            >
                                                <Typography className="!text-lg font-normal capitalize">
                                                    {item?.keywords || '-'}
                                                </Typography>
                                            </Button>
                                        ))
                                    }
                                </div>
                            </div>
                        </Show>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};
export default DialogSearch;
